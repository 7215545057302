import { useEffect, useState } from "react";
import FontFaceObserver from "fontfaceobserver"
import Header from "../header";
import Company from "../company";
import Team from "../team";
import Contacts from "../contacts";
import "./app.scss";

function App() {
  const [playAnimation, setPlayAnimation] = useState(false);
  const [loadFont, setLoadFont] = useState(false);
  const [loadLogo, setLoadLogo] = useState(false);
  const [loadImgCompany, setLoadImgCompany] = useState(false);
  const options = { triggerOnce: true, threshold: 0.2 };

  const fontMolot = new FontFaceObserver("Molot");

  useEffect(() => {
    fontMolot.load(null, 20000).then(setLoadFont(true));
    
    const onPageLoad = () => {
      setPlayAnimation(true);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);

      return () => window.removeEventListener("load", onPageLoad);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={playAnimation && loadFont && loadLogo && loadImgCompany ? "content" : "content hide"}>
      <Header loadLogo={loadLogo} setLoadLogo={setLoadLogo} options={options} />
      <Company loadImgCompany={loadImgCompany} setLoadImgCompany={setLoadImgCompany} options={options} />
      <Team options={options} />
      <Contacts options={options} />
    </div>
  );
}

export default App;

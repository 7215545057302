import { useInView } from "react-intersection-observer";
import logo from "./../../assets/logo.svg";
import logoTb from "./../../assets/tablet/logo_tb.svg";
import "./header.scss";

function Header({ loadLogo, setLoadLogo, options }) {
  const [ref, inView] = useInView(options);

  return (
    <header className="header container">
      <h1 ref={ref} className={inView && loadLogo ? "header-title animation" : "header-title"}>
        <picture onLoad={() => setLoadLogo(true)}>
          <source media="(max-width: 750px)" srcSet={logoTb} width="200px" height="26px" alt="Web-Marketing" />
          <source media="(max-width: 1200px)" srcSet={logoTb} width="284px" height="37px" alt="Web-Marketing" />
          <img src={logo} width="364px" height="44px" alt="Web-Marketing" />
        </picture>
      </h1>
    </header>
  );
}

export default Header;

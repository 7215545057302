import { useInView } from "react-intersection-observer";
import { isRetina } from "../../utils/retina";
import img from "../../assets/desktop/image_company.png";
import imgRetina from "../../assets/desktop/image_company_retina.png";
import imgTb from "../../assets/tablet/image_company_tb.png";
import imgRetinaTb from "../../assets/tablet/image_company_tb_retina.png";
import imgMb from "../../assets/mobile/image_company_mb.png";
import imgRetinaMb from "../../assets/mobile/image_company_mb_retina.png";
import "./company.scss";

function Company({ loadImgCompany, setLoadImgCompany, options }) {
  const [refTitle, inViewTitle] = useInView(options);
  const [refText, inViewText] = useInView(options);
  const [refImg, inViewImg] = useInView(options);

  return (
    <section className="company container">
      <div className="wrapper">
        <h2 ref={refTitle} className={inViewTitle ? "section-title animation" : "section-title"}>О компании</h2>
        <div ref={refText} className={inViewText ? "text-container animation" : "text-container"}>
          <p className="company-text">Компания «Веб Маркетинг», основанная в 2007 году, спе&shy;циали&shy;зируется на разра&shy;ботке, продвижении и продаже услуг сложных мульти&shy;язычных онлайн проектов.</p>
          <ul className="company-list">
            <li className="list-item">
              <h3>Технологии</h3>
              <p>Perl, Javascript, HTML5, PHP, Node.js.</p>
            </li>
            <li className="list-item">
              <h3>Штат</h3>
              <p>30+ специалистов</p>
            </li>
          </ul>
          <p className="company-text">В настоящий момент это центр разработки программного обес&shy;печения и сервисов для евро&shy;пейского классифайда коммерческого тран&shy;спорта и спец.техники <a href="https://www.truck1.eu/" className="link truck-link" target="_blank" rel="noopener noreferrer">Truck1.eu.</a></p>
        </div>
      </div>
      <div className="wrapper">
        <picture ref={refImg} onLoad={() => setLoadImgCompany(true)} className={inViewImg && loadImgCompany ? "company-img animation" : "company-img"}>
          <source media="(max-width: 750px)" srcSet={isRetina(imgMb, imgRetinaMb)} 
             width="343px" height="398px" alt="About company Web-Marketing" />
          <source media="(max-width: 1200px)" srcSet={isRetina(imgTb, imgRetinaTb)} 
            width="290px" height="334px" alt="About company Web-Marketing" />
          <img src={isRetina(img, imgRetina)} width="516px" height="588px" alt="About company Web-Marketing" />
        </picture>
      </div>
    </section>
  );
}

export default Company;

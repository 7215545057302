import { useInView } from "react-intersection-observer";
import { isRetina } from "../../utils/retina";
import call from "../../assets/call.svg";
import location from "../../assets/location.svg";
import arrow from "../../assets/desktop/arrow.svg";
import img from "../../assets/desktop/image_contacts.png";
import imgRetina from "../../assets/desktop/image_contacts_retina.png";
import imgTb from "../../assets/tablet/image_contacts_tb.png";
import imgRetinaTb from "../../assets/tablet/image_contacts_tb_retina.png";
import imgMb from "../../assets/mobile/image_contacts_mb.png";
import imgRetinaMb from "../../assets/mobile/image_contacts_mb_retina.png";
import "./contacts.scss";

function Contacts({ options }) {
  const [refTitle, inViewTitle] = useInView(options);
  const [refInfo, inViewInfo] = useInView(options);
  const [refPolicy, inViewPolicy] = useInView(options);
  const [refPolicyMb, inViewPolicyMb] = useInView(options);
  const [refText, inViewText] = useInView(options);
  const [refImg, inViewImg] = useInView(options);
  const [refArrow, inViewArrow] = useInView(options);

  return (
    <section className="contacts container">
      <div className="wrapper">
        <h2 ref={refTitle} className={inViewTitle ? "section-title animation" : "section-title"}>Контакты</h2>
        <ul ref={refInfo} className={inViewInfo ? "contacts-info animation" : "contacts-info"}>
          <li className="info-item">
            <div className="info-item-title">
              <img src={call} alt="Phone" />
              <h4>Телефон</h4>
            </div>
            <a className="link phone-link" href="tel:+375296292112">+375 (29) 629-21-12</a>
            <ul className="contacts-social">
              <li className="social-item">
                <a href="tg://resolve?domain=truck1career" target="_blank" rel="noreferrer" className="social-link telegram">
                  <span></span>
                </a>
              </li>
              <li className="social-item">
                <a href="https://wa.me/375296292112" target="_blank" rel="noreferrer" className="social-link whatsapp">
                  <span></span>
                </a>
              </li>
              <li className="social-item">
                <a href="viber://chat?number=375296292112" target="_blank" rel="noreferrer" className="social-link viber">
                  <span></span>
                </a>
              </li>
            </ul>
          </li>
          <li className="info-item">
            <div className="info-item-title">
              <img src={location} alt="Address" />
              <h4>Адрес</h4>
            </div>
            <p className="adress">ул. Клары Цеткин 51, пом. 14</p>
          </li>
        </ul>
        <p ref={refPolicy} className={inViewPolicy ? "contacts-policy animation" : "contacts-policy"}><a href="https://webm.by/Web_marketing_privacy_policy_01.pdf" target="_blank" rel="noreferrer" className="link policy-link">Наша политика конфиденциальности</a> обес&shy;печивает прозрачный характер обработки присланных Вами персональных данных, в соответствии с Законом Республики Беларусь от 07.05.2021 № 99-З «О защите персональных данных».</p>
      </div>
      <div className="wrapper">
        <p ref={refText} className={inViewText ? "contacts-text animation" : "contacts-text"}>Присылайте резюме на <a href="mailto:wm.career1@gmail.com" className="link mail-link">wm&shy;.career1&shy;@gmail.c&shy;om:</a> мы со&shy;риен&shy;тируем Вас об открытых вакансиях и опе&shy;ративно рассмотрим Вашу кандидатуру.</p>
        <picture ref={refArrow} className={inViewArrow ? "contacts-arrow animation" : "contacts-arrow"}>
          <source media="(max-width: 750px)" srcSet={arrow} 
            width="343px" height="54px" alt="Arrow" />
          <source media="(max-width: 1200px)" srcSet={arrow} 
            width="290px" height="46px" alt="Arrow" />
          <img src={arrow} width="517px" height="81px" alt="Arrow" />
        </picture>
        <p ref={refPolicyMb} className={inViewPolicyMb ? "contacts-policy mb animation" : "contacts-policy mb"}><a href="https://webm.by/Web_marketing_privacy_policy_01.pdf" target="_blank" rel="noreferrer" className="link policy-link">Наша политика конфиденциальности</a> обес&shy;печивает прозрачный характер обработки присланных Вами персональных данных, в соответствии с Законом Республики Беларусь от 07.05.2021 № 99-З «О защите персональных данных».</p>
        <picture ref={refImg} className={inViewImg ? "contacts-img animation" : "contacts-img"}>
          <source media="(max-width: 750px)" srcSet={isRetina(imgMb, imgRetinaMb)} 
            width="245px" height="147px" alt="Contacts Web-Marketing" />
            <source media="(max-width: 1200px)" srcSet={isRetina(imgTb, imgRetinaTb)} 
            width="207px" height="124px" alt="Contacts Web-Marketing" />
          <img src={isRetina(img, imgRetina)} width="368px" height="220px" alt="Contacts Web-Marketing" />
        </picture>
      </div>
    </section>
  );
}

export default Contacts;

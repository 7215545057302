import { useInView } from "react-intersection-observer";
import "./team.scss";

function Team({ options }) {
  const [refTitle, inViewTitle] = useInView(options);
  const [refText, inViewext] = useInView(options);
  const [refText2, inViewText2] = useInView(options);
  const [refStar, inViewStar] = useInView(options);

  return (
    <section className="team">
      <div className="container">
        <h2 ref={refTitle} className={inViewTitle ? "section-title animation" : "section-title"}>Команда</h2>
        <p ref={refText} className={inViewext ? "team-text animation" : "team-text"}>Веб Маркетинг - команда инициативных, талант&shy;ливых людей, работающих вместе в продуктовой гармонии.</p>
        <p ref={refText2} className={inViewText2 ? "team-text animation" : "team-text"}>У нас в приоритете орга&shy;низо&shy;ванность и мотивация. Основ&shy;ными нашими ценно&shy;стями являются ориентированность на команду, прозрачность процессов и решений. Мы ценим каждого, кто с нами.</p>
        <div ref={refStar} className={inViewStar ? "star animation" : "star"}></div>
      </div>
    </section>
  );
}

export default Team;
